import { Controller } from "stimulus";
import newCart from "util/new_cart.js";


export default class extends Controller {
  static targets = ["cartItemsCount"];

  connect () {
    this.updateCartItemsCount();
  }


  updateCartItemsCount() {
    newCart.size.then(size => {
      if (size > 0) {
        this.cartItemsCountTarget.classList.remove("hidden");
        this.cartItemsCountTarget.textContent = size;
      } else {
        this.cartItemsCountTarget.classList.add("hidden");
        this.cartItemsCountTarget.textContent = "";
      }
    })
  }
}
